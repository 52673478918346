import React from 'react'
import Contact from '../components/contact/contact'


const contactus = () => {
    return (
        <>
            <Contact />
        </>
    )
}

export default contactus