import React from 'react'
import Gallery from '../components/gallery/gallery'

const events = () => {
  return (
    <>
      <Gallery />
    </>
  )
}

export default events